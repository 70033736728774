/* @import url('https://fonts.googleapis.com/css?family=Pacifico|VT323|Quicksand|Inconsolata');
 @import url('https://fonts.googleapis.com/css2?family=Merriweather&family=Poppins&family=Roboto&family=Style+Script'); */

@import url("../../fonts/fonts.css");

.certificateList {
  width: 400px;
}
.certificateSec {
  .templateAccordion {
    box-shadow: 4px 5px 0px rgba(0, 0, 0, 0.11) !important;
    border-left: 6px solid rgba(0, 0, 0, 0.1) !important;
    border-radius: 10px !important;
    margin-bottom: 10px;
    overflow: hidden;
    .accordion-head {
      p {
        font-weight: 600;
      }
    }
    .templateAccordionContent {
      border-top: 1px solid #c0c0c0;
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      max-height: 375px;
      overflow: auto;
      gap: 15px;
      justify-content: space-around;
      button {
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
          0px 2px 2px 0px rgba(0, 0, 0, 0.14),
          0px 1px 5px 0px rgba(0, 0, 0, 0.12);
        border: 2px solid transparent;
        border-radius: 4px;
        &:active,
        &:focus {
          border: 5px solid #1b89f0;
        }
      }
    }
  }
  .btnArea {
    position: relative;
    margin-top: 10px;
    margin-bottom: 30px;
    .notSelected {
      cursor: not-allowed;
      &:hover + .errorBox {
        position: absolute;
        display: block;
        top: -50px;
        transform: translate(0%, 0%);
        width: 100%;
        .errorMssg {
          padding: 10px;
          width: max-content;
          margin: 0 auto;
          border: 1px solid;
          border-radius: 8px;
          font-weight: 600;
        }
      }
    }
  }
  .errorBox {
    display: none;
  }
  .loadingScreen {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba(0, 0, 0, 0.53);
    display: flex;
    align-items: center;
  }
  .templateSec {
    position: relative;
    overflow: hidden;
    .templateCard {
      box-shadow: 4px 5px 5px rgba(97, 96, 96, 0.3) !important;
      border: 1px solid #d3d3d3;
      display: flex;
      justify-content: center;

      .defaultTemp {
        position: absolute;
        transform: translate(0%, 0%);
        width: calc(100% - 18px);
        height: calc(100% - 18px);
        display: flex;
        align-items: center;
        border-radius: 4px;
        justify-content: center;
        font-weight: 600;
        font-size: 20px;
        text-align: center;
      }
      canvas {
        transition: all 0.3s ease-in-out;
      }
    }
    #deleteBtn {
      padding: 0;
      svg {
        font-size: 36px;
      }
    }
  }
  .customCardContent {
    .certificateData {
      overflow: unset;
      box-shadow: 4px 5px 0px rgba(0, 0, 0, 0.11) !important;
      border-left: 6px solid rgba(0, 0, 0, 0.1) !important;
      border-radius: 10px !important;
      .cardHeader {
        min-height: 60px;
        padding: 0 16px;
        font-weight: 600;
        /*font-family: "Roboto", "Helvetica", "Arial", sans-serif;*/
        font-size: 1rem;
        line-height: 1.5;
        letter-spacing: 0.00938em;
        color: #101010;
        display: flex;
        align-items: center;
        border-bottom: 1px solid;
      }
      .attachLabel {
        cursor: pointer;
        .innerTxt {
          font-weight: 600;
          font-size: 14px;
          margin-bottom: 5px;
          display: inline-block;
        }
      }
      .uploadedSign {
        max-width: 100px;
      }
      .uploadedLogo {
        max-width: 100px;
      }
      .fontSec {
        label {
          font-weight: 500;
          font-size: 15px;
          margin-bottom: 5px;
          display: inline-block;
        }
        .align {
          background: #c8d8ed;
          border-radius: 4px;
          button {
            padding: 8px;
            min-width: 50px;
            /*For Mobile responsive*/
            @media screen and (max-width: 480px) {
              min-width: auto;
            }
            /*For Mobile responsive*/
            &.selected {
              background: #9baee1;
            }
            svg {
              font-size: 20px;
              /*For Mobile responsive*/
              @media screen and (max-width: 480px) {
                font-size: 15px;
              }
              /*For Mobile responsive*/
            }
          }
        }
      }
    }
    .textSec {
      .ScrollbarsCustom {
        /*For Mobile responsive*/
        @media screen and (max-width: 900px) {
          min-height: calc(-15px + 72vh) !important;
        }
        /*For Mobile responsive*/
      }
      .ScrollbarsCustom-Content {
        display: flex;
      }
    }
  }
}

.downloadBtn {
  position: absolute !important;
  z-index: 9;
  padding: 5px 15px !important;
  right: 0;
  bottom: 0;
}
.light {
  .defaultTemp {
    background-color: #ffffff;
    background-image: linear-gradient(315deg, #6a93cb17 0%, #a4bfef80 74%);
  }
  .certificateData {
    background-color: #ffffff;
    background-image: linear-gradient(
      315deg,
      rgba(106, 147, 203, 0.35) 0%,
      rgba(164, 191, 239, 0.14) 74%
    );
  }
}
.dark {
  .defaultTemp {
    background-color: #7f5a83;
    background-image: linear-gradient(315deg, #7f5a83 0%, #0d324d 74%);
    color: #ffffff;
  }
  .certificateData {
    background-color: #20374e;
    background-image: linear-gradient(
      315deg,
      rgba(87, 114, 189, 0.35) 0%,
      rgba(164, 191, 239, 0.14) 74%
    );
    color: #ffffff;
  }
}

// @media screen and (min-width: 991px) and (max-width: 1315px) {
//   .customCardContent {
//     .textSec {
//       max-width: 40% !important;
//       flex-basis: 40% !important;
//     }
//     .templateSec {
//       max-width: 60% !important;
//       flex-basis: 60% !important;
//     }
//   }
// }
