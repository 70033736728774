#accountMenu {
  background-color: rgba(0, 0, 0, 0.7);
  .MuiMenu-paper.MuiPaper-elevation {
    // max-width: calc(100% - 27%);
  }
}
.customDropdown {
  &.menuDropdown {
    ul {
      display: grid;
      padding: 10px 10px !important;
      margin: 0px;
      resize: horizontal;
      gap: 0.5rem;
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      width: 100%;
      /*For Mobile responsive*/
      @media screen and (max-width: 557px) {
        padding: 28px 10px 10px !important;
      }
      /*For Mobile responsive*/
      li.close-btn {
        position: absolute;
        top: -6px;
        left: -1px;
        background: #2769aa;
        padding: 10px 7px 2px;
        border-radius: 0 0 15px 0;
      }
      .quickLinkItem {
        border-radius: 25px;
        padding: 15px;
        display: flex;
        align-items: center;
        position: relative;
        overflow: hidden;
        font-weight: bold;
        text-transform: uppercase;
        cursor: pointer;
        box-shadow: 0px 0px 12px 2px #c9dbfc inset;
        color: #000 !important;
        transition: all 0.3s ease-in-out;
        justify-content: space-around;
        border: 1px solid #e4e4e4;
        max-width: 180px;
        width: 100%;
        margin: 0 auto;
        // @media screen and (max-width: 1200px) {
        //   max-width: calc(100% / 5 - 1%);
        // }
        // @media screen and (max-width: 768px) {
        //   max-width: calc(100% / 3 - 1%);
        // }
        // @media screen and (max-width: 480px) {
        //   max-width: calc(100% / 2 - 2%);
        //   margin: 1%;
        // }

        &:hover {
          .overlay {
            height: 100%;
            transform: translateY(-50%);
            left: 0;
            .overlayTxt {
              opacity: 1;
            }
          }
        }
        &:focus {
          background-color: #ccdeff;
          border: 2px solid #77a4f5;
        }
        .imgBox {
          position: relative;
          text-align: center;
          img {
            height: 80px;
            transition: all 0.5s;
          }
          .count {
            position: absolute;
            top: 0px;
            right: 15px;
            border-radius: 100%;
            background-color: red;
            color: #fff;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            font-size: 14px;
            border: 1px solid;
          }
        }
        .text {
          font-size: 16px;
          text-transform: capitalize;
          font-weight: 600;
          text-align: center;
          line-height: 20px;
          white-space: break-spaces;
        }

        &--item {
          border-radius: 10px;
          padding: 15px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          overflow: hidden;
          transition: all 0.3s;
          font-weight: bold;
          text-transform: uppercase;
          cursor: pointer;

          letter-spacing: 1px;
          min-height: 150px;

          a {
            text-decoration: none;
          }

          img {
            height: 95px;
            transition: all 0.5s;
          }

          .text {
            transform: scale(1);
            transition: all 0.5s;
          }

          &:hover {
            box-shadow: 0px 0px 0px 0px #898989;

            img {
              height: 75px;
            }
          }
        }
        .quickLink.MuiBox-root {
          width: 100%;
          word-wrap: break-word;
        }
      }
    }
  }
}

#accountMenu.main-menu-blk .MuiPaper-root {
  left: 0 !important;
  right: 0;
  margin: 0 auto;
  max-width: calc(75% - 32px) !important;
  position: relative;
  /*For Mobile responsive*/
  @media screen and (max-width: 1200px) {
    max-width: calc(95% - 32px) !important;
    max-height: calc(90% - 32px);
  }
  /*For Mobile responsive*/
}
