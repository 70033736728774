.showMore{
    text-align: right;
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;

    &-box{
       color: #2769aa;
       cursor: pointer;
    }
}