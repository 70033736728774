.teacherTimeTblWrapper {
  .teacherTimeTblInfo {
    width: 210px;
  }
  .weekBox {
    padding: 0px;
    max-width: 100px;
    .subjTitle {
      font-size: 14px;
      font-weight: 600;
    }
    .subTitle{
      font-size: 12px;
    }
    &.yellowShade {
      background-color: #fff1db;
    }
    &.blueShade {
      background-color: #eef2ff;
      border-radius: 10px;
      padding: 10px;
    }
  }
}
