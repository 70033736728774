.adminList {
  border: 1px solid #e0ebff;
  border-radius: 10px;
  transition: all 1.5s;
  text-decoration: none;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  text-align: center;
  justify-content: center;
  padding: 25px;
  min-width: 220px;
  min-height: 210px;
  .listImg {
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    width: 120px;
    height: 120px;
  }
  .listHead {
    font-size: 18px;
    font-weight: 600;
    color: #1b1b1b;
  }

  &.green {
    background: linear-gradient(47deg, #7fe5ba 0%, #b4dbc9 40%, #daf9eb 100%);
    .listImg {
      background: #d0f2e3;
    }
  }
  &.orange {
    background: linear-gradient(47deg, #e1b9aa 0%, #f9c7b3 40%, #fdddd0 100%);
    .listImg {
      background: #fde8e0;
    }
  }
  &.yellow {
    background: linear-gradient(47deg, #e5ce8f 0%, #ffeab7 40%, #f6e9c4 100%);
    .listImg {
      background: #f8eed4;
    }
  }
  &.purple {
    background: linear-gradient(47deg, #9d98bf 0%, #c4c9ec 40%, #c2c5dd 100%);
    .listImg {
      background: #d7daf7;
    }
  }
  &.red {
    background: linear-gradient(47deg, #fd6b6b 0%, #ff9d9d 40%, #ffdcdc 100%);

    .listImg {
      background: #ffd8d8;
    }
  }
  &.blue {
    background: linear-gradient(47deg, #a3d9ff 0%, #dbf3ff 40%, #b6d9ed 100%);
    .listImg {
      background: #ecf8ff;
    }
  }
  &.darkblue {
    background: linear-gradient(47deg, #ffbf66 0%, #ffe0b6 40%, #f6d7ac 100%);
    .listImg {
      background: #fff6ea;
    }
  }
  &.lightblue {
    background-color: #bdd4e7;
    background-image: linear-gradient(315deg, #bdd4e7 0%, #bacae9 74%);
    .listImg {
      background: #fafbff;
    }
  }
  &.brown {
    background: linear-gradient(47deg, #f9a76b 0%, #f1bf95 40%, #f9ab78 100%);
    .listImg {
      background: #ffdabb;
    }
  }
}
.adminInSec {
  display: flex;
  gap: 25px;
  flex-wrap: wrap;
  .adminInList {
    text-decoration: none;
    width: calc(95% / 2);
    display: inline-block;
    padding: 10px 20px;
    border-radius: 7px;
    text-align: center;
    box-shadow: 0 2px 12px 4px rgba(0, 0, 0, 0.2);
    background: linear-gradient(47deg, #f0efef 0%, #f5eeec 40%, #fff6f2 100%);
    .listHead span {
      color: #101010;
      font-weight: 600;
    }
  }
}
