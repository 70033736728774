body {
  font-display: swap;
  .loaderSec {
    background: rgba(0, 0, 0, 0.85);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    img {
      width: 100px;
    }
  }
  .scroll-to-top {
    transition: 0.6s ease-in-out;
  }
  .MuiContainer-root {
    padding: 0 10px;
  }

  //HEading css
  .mainHeading {
    font-size: 20px;
    font-weight: 500;
    font-family: "Fredoka" !important;
    letter-spacing: 0.4px;
  }
  .customCard {
    .title {
      border-bottom: 1px solid #d3d3d3;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      /*For Mobile responsive*/
      @media (max-width: 640px) {
        align-items: center;
        font-size: 0.8rem;
      }
      /*For Mobile responsive*/
      .titleHead {
        display: flex;
        align-items: center;
        gap: 8px;
        /*For Mobile responsive*/
        @media (max-width: 480px) {
          max-width: 60%;
        }
        /*For Mobile responsive*/
      }
      svg {
        cursor: pointer;
      }
      /*For Mobile responsive*/
      @media (max-width: 640px) {
        img {
          max-width: 30px;
          min-height: 30px !important;
        }
        .btn {
          font-size: 0.7rem;
          padding: 4px 6px;
          min-width: 145px;
        }
      }
      /*For Mobile responsive*/
    }
  }
  .MuiDialogTitle-root {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 20px;
    text-transform: capitalize;
    button {
      min-width: 30px;
      min-height: 30px;
      // border-radius: 100%;
      // padding: 0;
      box-shadow: none;
      background: transparent;
      svg {
        font-size: 24px;
      }
    }
  }
  .MuiDialogTitle-root + .MuiDialogContent-root {
    padding-top: 20px;
    // margin: 0px auto 40px;
    background: transparent;
  }
  .img-fluid {
    max-width: 100%;
    height: auto;
  }
  .btn {
    padding: 8px 16px;
    min-width: 120px;
    &:hover {
      opacity: 0.8;
    }
  }
  .circleBtn {
    border-radius: 100%;
    min-width: 40px;
    height: 40px;
    padding: 0 !important;
    svg {
      font-size: 20px;
      color: #2769aa;
      path {
        stroke: #2769aa;
      }
    }
    &:hover {
      background-color: #b9d5ef;
    }
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }

  @-moz-document url-prefix() {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      appearance: none;
    }
  }
  .secOutBtn {
    border: 1px solid inherit;
  }
  .modalCard {
    .title {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    .eventFooter {
      border-top: 1px solid #d3d3d3;
    }
    .modalLabel {
      font-size: 0.8rem;
      font-weight: 700;
    }
  }
  //buttons scss
  .btns2 {
    &:hover {
      background-color: #8dbceb !important;
      color: #ffffff;
    }
  }
  // Light theme common SCSS Start
  .App.light {
    .MuiDataGrid-columnHeaders {
      background-color: #eaedf5;
      color: #647491;
    }
    .MuiListItemButton-root {
      &:hover {
        background-color: #ffb703;
        .MuiListItemIcon-root {
          path {
            fill: #000;
          }
          svg {
            fill: #000 !important;
          }
        }
        .MuiListItemText-root {
          span {
            color: #000;
            font-weight: 700 !important;
          }
        }
      }
      &.active {
        background-color: #0081ff;
        .MuiListItemIcon-root {
          path {
            fill: #ffffff;
          }
          svg {
            fill: #ffffff !important;
          }
        }
        .MuiListItemText-root {
          span {
            color: #ffffff;
          }
        }
      }
    }
    .customTbl {
      button.MuiButtonBase-root {
        p {
          font-size: 0.8rem;
        }
        &:hover,
        &:active {
          background-color: #055199;
          border-color: #006cd5;
          p,
          svg {
            color: #ffffff;
          }
        }
        &.Mui-selected {
          background: #0081ff !important;
        }
      }
    }
    .MuiCheckbox-root.MuiCheckbox-colorPrimary {
      color: #6e6e6e;
      input[type="checkbox"] {
        &:checked + svg {
          color: #0081ff;
        }
      }
    }
  }
  .secondaryBtn {
    border: 2px solid #2769aa;
    color: #2769aa;
    font-weight: 600;
    &:hover {
      color: #ffffff !important;
    }
  }
  .primaryBtn {
    border: 2px solid #2769aa;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: #fff;
    background-color: #2769aa;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    text-transform: capitalize;
    min-width: 64px;
    padding: 6px 16px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-decoration: none;
  }
  // Light theme common SCSS end
  // Dark theme common SCSS Start
  .App.dark {
    .breadcrumbs {
      a,
      .separator {
        color: #ebebeb;
      }
    }
    .MuiDataGrid-columnHeaders {
      background-color: #14283c;
      color: #647491;
    }
    .secondaryBtn {
      border: 2px solid #2c5176;
      color: #2c5176;
      font-weight: 600;
      &:hover {
        color: #ffffff !important;
      }
    }
    .primaryBtn {
      border: 2px solid #2c5176;
    }
    .secOutBtn:hover {
      border: 1px solid #2c5176;
      color: #2c5176 !important;
      background-color: #dfdfdf;
    }

    .MuiDataGrid-columnHeaderTitle,
    .MuiDataGrid-cellContent,
    .MuiButton-containedPrimary p,
    .MuiCheckbox-root svg,
    .MuiTablePagination-toolbar,
    .MuiDataGrid-selectedRowCount {
      color: #dfdfdf;
    }

    button {
      &.MuiButtonBase-root {
        p {
          font-size: 0.8rem;
        }
        &:hover {
          // background-color: #0081ff;
          p,
          svg {
            color: #dfdfdf;
          }
        }
      }
    }
    .MuiRadio-root {
      span {
        svg {
          color: #ebebeb;
        }
      }
    }
    .circleBtn {
      border-radius: 100%;
      min-width: 45px;
      height: 45px;
      padding: 0 !important;
      svg {
        font-size: 20px;
        color: #dfdfdf;
        path {
          stroke: #dfdfdf;
        }
      }
      &:hover {
        background-color: #0081ff;
      }
    }
    .Mui-disabled {
      background: #243c54;
      margin: 0 5px;
    }
    .Mui-checked {
      color: #749ec9;
    }
    .Mui-disabled {
      border-color: #2c5176;
      color: #c4c4c4;
    }
    .MuiDataGrid-cell {
      color: #fff;
    }
  }
  // Dark theme common SCSS End

  .modal-btn-wrap-div .btn {
    margin: 5px;
  }
}

// common scss
.cardBox {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  &:hover {
    box-shadow: 5px 3px 12px rgb(0 0 0 / 0.2);
  }
}
.overlay {
  position: absolute;
  top: 50%;
  width: 100%;
  height: 0;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.85);
  transition: all 0.2s;
  transform: translateY(-50%);
  .overlayTxt {
    padding: 20px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    .subject {
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0.1rem;
      text-align: center;
    }
    p {
      color: #fff;
    }
  }
}
.MuiPaper-rounded {
  border-radius: 15px !important;
}
.popupCloseButton {
  position: absolute !important;
  right: 10px;
  top: 10px;
}
.customTbl {
  h4 {
    border-bottom: 1px solid #ddd;
    padding: 15px 20px 10px;
    margin-bottom: 20px;
    font-size: 28px;
  }
  .customTblcontnet {
    padding: 0px 15px;
    .MuiDataGrid-columnHeadersInner div {
      font-weight: bold;
    }
    .MuiDataGrid-withBorderColor {
      border-color: transparent !important;
      & + .customVirtualScroller {
        min-height: 56px;
      }
    }
    .MuiDataGrid-virtualScrollerContent {
      min-height: 500px !important;
      height: 100%;
    }
    .MuiDataGrid-cell:focus,
    .MuiDataGrid-actionsCell:focus {
      outline: 0;
    }

    //table customization
    .MuiDataGrid-row {
      border-radius: 15px;
      margin: 5px 1px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
      width: 99%;
    }
    .boldHeader {
      font-weight: bold;
    }
    .customCell {
      justify-content: end;
    }
  }
}
.MuiDataGrid-withBorderColor {
  & + .customVirtualScroller {
    min-height: 56px;
  }
}
// .error {
//   color: white !important;
//   font-weight: bolder !important;
//   opacity: 0.8 !important;
//   &:before {
//     content: "";
//     width: 10px;
//     height: 10px;
//     border-radius: 100%;
//     background-color: #ff0000;
//     position: absolute;
//     top: 15px;
//     left: 31%;
//   }
// }
//SCSS for custom Modal
.MuiDialog-paper {
  border-radius: 10px !important;
  box-shadow: 0px 0px 17px 2px #8b8b8b inset !important;
  // overflow-y: visible !important;

  /*For Mobile responsive*/
  @media screen and (max-width: 425px) {
    width: calc(100% - 10px) !important;
    margin: 32px 10px !important;
  }
  /*For Mobile responsive*/
  .MuiDialogContent-root {
    // background: transparent;
  }
  .MuiDialogActions-root {
    background: transparent;
    border-top: 1px solid #ddd;
  }
}
.App.dark {
  footer div {
    &:before {
      background-color: rgba(12, 33, 54, 0.79);
    }
  }
}
.notSelected.MuiButtonBase-root {
  pointer-events: none;
  opacity: 0.6;
}

.MuiPickersCalendarHeader-switchViewButton {
  display: none !important;
}

.calendar-container {
  button.back-btn:hover {
    background-color: transparent !important;
  }
}

.errorBox {
  position: absolute;
  z-index: 2;
  width: 100%;
  .errorContent {
    width: 60%;
    margin: 0 auto;
  }
}

.pointer{
  cursor: pointer;
}
.notesDialogBox {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: #fff;
    -webkit-cursor-color: #fff;
    -webkit-box-shadow: transparent inset; /* Change this color to the desired background */
    transition: background-color 5000s ease-in-out 0s;
    caret-color: white;
  }

  input:-moz-autofill,
  textarea:-moz-autofill,
  select:-moz-autofill {
    box-shadow: transparent inset; /* Change this color to the desired background */
    -moz-text-fill-color: #fff;
  }
}

.bgText {
  border: 2px solid #cfdcfd;
  background-color: #dfe8ff;
  padding: 20px;
  border-radius: 15px;
}
.iconBtns {
  background: #e2f0ff !important;
  svg {
    color: #2769aa;
  }
}
.mssgCard {
  border: 2px solid #cfdcfd;
  background-color: #dfe8ff;
  padding: 20px;
  border-radius: 15px;
}

/*responsive text in dialog box*/
.content-div-dialog p {
  white-space: normal;
  /*For Mobile responsive*/
  @media screen and (max-width: 425px) {
    font-size: 0.8rem;
  }
  /*For Mobile responsive*/
}
