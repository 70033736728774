.customCardItem {
  height: calc(100vh - 270px);
  overflow: auto;
  .syllabusCheck {
    z-index: 2;
  }
  .completeSyllabus {
    position: absolute;
    right: 39px;
    top: 12px;
    z-index: 1;
    display: flex;
    gap: 8px;
    align-items: center;
  }
}
.customAccordion {
  margin-top: 10px;
  border-top: 0 !important;
  border-radius: 15px;
  overflow: hidden;
  .accordionSumm {
    position: relative;
    font-weight: 600;
    font-size: 18px;

    .MuiAccordionSummary-expandIconWrapper {
      position: absolute;
      right: 20px;
    }
  }
  .innerAccordionHEad {
    background-color: rgb(212 222 247);
  }

  .accordionDetail {
    border-bottom: 1px solid #c1c9e0;
    .innerAccordion {
      border-radius: 15px;
      overflow: hidden;

      .innerAccordionSumm {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
      }
    }
  }
}
.courseLink {
  width: calc(100% + 0px) !important;
  padding: 0;
  margin-left: 0 !important;
  .linkData {
    width: 100%;
  }
}
.courseContent {
  width: 100%;
  .courseLinks {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    text-decoration: none;
    padding: 15px 20px;
    font-weight: 600;
    border-bottom: 1px solid #ddd;
    &:last-child {
      border-bottom: 0;
    }
    .courseIcon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.068);
      cursor: pointer;
    }
  }
}

.light {
  .customAccordion {
    background-color: #dbe5ff !important;
  }
}
.dark {
  .customAccordion {
    background-color: #1f354b !important;
    color: #ebebeb;
    svg {
      color: #ebebeb;
    }
  }
}
.completionBox {
  margin: 20px;

  .completionBoxData {
    display: block;
    .imgSec {
      border: 2px solid #2d4356;
      border-radius: 50px;
      padding: 0px 20px 0px 0px;
      display: flex;
      align-items: center;
      gap: 5px;
      margin: 40px 0 20px 0;
      &:hover {
        background-color: #dbe5ff;
      }
    }
    .MuiCheckbox-root {
      display: none;
    }

    @keyframes slide-right {
      from {
        transform: translateX(0);
      }
      to {
        transform: translateX(20px);
      }
    }

    @keyframes slide-left {
      from {
        transform: translateX(20px);
      }
      to {
        transform: translateX(0);
      }
    }

    .MuiFormControlLabel-root.slide-right .toggle-image {
      opacity: 1;
    }

    .MuiFormControlLabel-root.slide-left .toggle-image {
      opacity: 1;
    }
  }

  .closeRedBtn {
    border-radius: 5px !important;
    background-color: #ed4152;
    box-shadow: 0px 0px 25px 3px #f37985 inset;
    color: #ffffff !important;
    min-width: 80px !important;
    margin: 10px auto;
    height: 40px;
    &:hover {
      background-color: #ee4e5e;
    }
  }
}
.progressReport {
  margin-top: 20px;
}
.assetsBoxes {
  .assetChecked {
    width: 34px;
    position: absolute;
    right: 0px;
    top: 0px;
  }
  .lockedBg {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    &::before {
      content: "";
      width: 0px;
      height: 0px;
      border-left: solid 40px #f57373;
      border-bottom: solid 40px transparent;
      border-top: solid 40px transparent;
      position: absolute;
      rotate: -45deg;
    }
    svg {
      z-index: 1;
      padding-right: 5px;
      padding-top: 5px;
    }
  }
  .overlayBox .dataInfo {
    font-size: 12px;
    position: absolute;
    left: 10px;
    bottom: 5px;
    color: #fff;
    opacity: 0;
    transition: opacity 0.3s ease;
    letter-spacing: 0.5px;
  }
  .overlayBox:hover .dataInfo {
    opacity: 1;
  }
}

// added padding for accordion arrow
.customAccordion
  .accordionSumm.css-uvvswg-MuiButtonBase-root-MuiAccordionSummary-root {
  padding-right: 35px;
}
