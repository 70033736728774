
.recentSec {
    // padding: 20px;
    border-radius: 15px;
    box-shadow: 0 7px 18px 0 rgba(0, 0, 0, 0.1), 0 3px 7px 0 rgba(0, 0, 0, 0.18);
    .recentItemHeader {
      font-size: 20px;
      font-weight: 500;
    }
    .recentList {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      padding: 20px;
      .navLinks {
        width: 100%;
        padding: 10px;
        border-radius: 10px;
        text-decoration: none;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        position: relative;
        overflow: hidden;
        &:hover {
          -webkit-transform: translateY(-5px);
          -moz-transform: translateY(-5px);
          -ms-transform: translateY(-5px);
          -o-transform: translateY(-5px);
          transform: translateY(-5px);
        }
        .customBox {
          z-index: 1;
          position: relative;
          display: flex;
          align-items: start;
          .imgSec {
            padding: 6px 3px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
          }

          .title {
            font-weight: 600;
            font-size: 18px;
          }
          .subTitle {
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
          }
        }
      }
    }
  }


//theme base color changes
.light {
  .recentList {
    .navLinks {
      // box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);

      border: 2px solid #cfdcfd;
      background-color: #dfe8ff;
    }
  }
}

.dark {
  .recentList {
    .navLinks {
      border: 2px solid #283849;
      // box-shadow: 0 10px 15px -3px rgba(177, 195, 222, 0.26), 0 4px 6px -4px rgba(177, 195, 222, 0.26) ;
      background: #2a394a;
      .imgSec img{
        filter: invert(1) brightness(200);
      }
    }
    
  }
}
