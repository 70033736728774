.progressHeader {
  text-align: center;
  font-size: 32px;
  font-weight: 500;
  background: #2769aa;
  color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 6px 10px;
}
.scoreContainer {
  min-width: 100%;
  width: max-content;
  border: 1px solid #ddd;
  overflow: hidden;
  .scoreView {
    display: flex;
    align-items: center;
    &.scoreHeading {
      background-color: #fff;
      display: flex;
      box-shadow: 0px 0px 12px 2px #c9dbfc inset;
      font-weight: bold;
      color: #2769aa;
      .scoreBox {
        padding: 10px;
      }
    }
    &.scoredata {
      color: #5c5c5c;
      border-bottom: 1px dotted #9f9f9f;
      &:last-child {
        border-bottom: 0;
      }
    }
    .rollNo {
      min-width: 80px;
    }
    .name {
      width: 140px;
      padding-left: 5px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-size: 14px;
    }
    .scoreBox {
      margin: 0 5px;
      width: 90px;
      text-align: center;
      color: #2769aa;
    }
    .borderBox {
      border-left: 1px dotted #9f9f9f;
      padding: 10px 0;
      &:first-child {
        border: 0;
        text-align: center;
      }
      &.name {
        padding-left: 5px;
        font-weight: 600;
        color: #444444;
        cursor: pointer;
        &:hover {
          color: #2769aa;
        }
      }
    }
  }
}

// .progressCard .customCardItem {
//   border: 1px solid #b1cae9;
// }
.progressContent {
  width: 100%;
  overflow: hidden;
  border: 1px solid #b1cae9;
  #loader-1 #loader {
    width: 10vw;
    height: 10vw;
  }
  .activitySec {
    .MuiAccordion-root.MuiAccordion-rounded {
      margin: 0;
      border-radius: 0 !important;
      &.Mui-expanded {
        margin: 0;
      }
      .MuiButtonBase-root.MuiAccordionSummary-root {
        min-height: 54px !important;
        background-color: #e1ebfb;
        .MuiAccordionSummary-content {
          justify-content: space-between;
          margin: 15px 10px;
          align-items: center;
          p.accord-title-std-rpt:nth-child(2) {
            text-align: right;
          }
          /*For Mobile responsive*/
          @media (max-width: 580px) {
            p.accord-title-std-rpt {
              flex: 1;
              margin: 0 3px;
              font-size: 0.8rem;
            }
          }
          /*For Mobile responsive*/
        }
        .MuiAccordionSummary-expandIconWrapper {
          position: absolute;
          left: 4px;
        }
      }
      .MuiCollapse-wrapperInner {
        background-color: #eaeffb;
        .MuiAccordionDetails-root {
          > div {
            padding: 5px 0px;
            /*For Mobile responsive*/
            @media (max-width: 580px) {
              p {
                font-size: 0.8rem;
                padding-bottom: 5px;
              }
            }
            /*For Mobile responsive*/
          }
          div.mb-scored-act-div:last-of-type {
            border: 0;
          }
        }
      }
    }
  }
}

.completed {
  color: green;
}

.not-completed {
  color: #a80000;
}
.activityHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px;
  border: 1px solid #b1cae9;
  background-color: #d4e3fb;
  /*For Mobile responsive*/
  @media (max-width: 580px) {
    padding: 10px 15px;
  }
  p {
    font-size: 18px;
    font-weight: 600;
    /*For Mobile responsive*/
    @media (max-width: 580px) {
      font-size: 16px;
    }
    /*For Mobile responsive*/
  }
  /*For Mobile responsive*/
  @media (max-width: 580px) {
    button.btn {
      font-size: 10px;
      min-width: 95px;
    }
  }
  /*For Mobile responsive*/
}
.insideHeader {
  p {
    font-size: 16px;
    font-weight: 500;
  }
  .MuiGrid-item {
    border-bottom: 1px solid #d1dff5;
  }
}
