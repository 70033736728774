.customAccordion {
  .accordionSumm {
    background-color: #d4def7;
   
  }
  .MuiAccordionSummary-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
    margin: 6px 0;
     &.Mui-expanded{
      margin: 10px 0;
    }
  }
  .accordionDetail{
    padding-top: 20px;
    &.mainAccordion {
      .activityContent {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        gap: 25px;
        .assetsBoxes {
          width: calc(100% / 4 + -20px);
          min-width: 180px;
        }
      }
    }
  }
  
}
