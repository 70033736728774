.footer {
  margin-top: 20px;
  &-wrapper {
    padding: 60px 0 60px;
    background: url(../../images/footerBg.png) no-repeat;
    background-size: 100% 100%;
    position: relative;

    @media only screen and (max-width: 900px) {
      padding: 50px 0 50px;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgb(39 105 170 / 83%);
    }
    .footerContent {
      position: relative;
      z-index: 1;
    }
    span {
      &.icon {
        &.mailicon {
          right: 2px;
        }
      }
    }

    h3 {
      font-weight: 600;
      line-height: 36px;
      font-size: 24px;
    }

    &-btn {
      flex-wrap: wrap;
      gap: 5px;

      button {
        padding: 5px !important;
        margin-left: 5px;
        border-radius: 5px !important;
        min-width: 25px !important;

        &:first-child {
          margin-left: 0;
        }
      }
    }

    .social-media {
      h6 {
        font-size: 16px;
        font-weight: 500;
        margin-top: 20px;
        margin-bottom: 15px;
      }
    }

    &-col {
      padding: 30px !important;
      margin: 0 10px;
      height: 100%;

      .social-media {
        margin-bottom: 10px;
      }

      a {
        color: inherit;
        text-decoration: none;
        line-break: anywhere;

        &:hover {
          text-decoration: underline;
        }
      }

      span {
        &.icon {
          display: inline-block;
          position: relative;
          top: 7px;
        }
      }

      @media (max-width: 899px) {
        padding: 20px !important;
        margin-left: 15px;
        margin-bottom: 20px;
        margin-right: 15px;

        &:nth-child(2) {
          justify-content: flex-start;
        }
      }
    }

    &-links {
      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          a {
            font-size: 16px;
            line-height: 32px;
            display: block;
            text-decoration: none;
          }
        }
      }
    }
  }

  &-logo {
    img {
      max-width: 100%;
      height: auto;
      margin-bottom: 10px;
    }
  }

  &-box {
    &:first-child a {
      font-weight: 600;
    }

    .contact-info {
      h2 {
        margin-bottom: 15px;
      }

      &--icon {
        display: flex;
        align-items: center;
      }
    }
  }

  [data-tooltip] {
    position: relative;
    z-index: 2;
    cursor: pointer;
  }

  [data-tooltip]:before,
  [data-tooltip]:after {
    visibility: hidden;

    opacity: 0;
    pointer-events: none;
  }

  [data-tooltip]:before {
    position: absolute;
    bottom: 80%;
    left: 50%;
    margin-bottom: 5px;
    margin-left: -50px;
    padding: 7px;
    width: 80px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background-color: #000;
    color: #fff;
    content: attr(data-tooltip);
    text-align: center;
    font-size: 14px;
    line-height: 1.2;

    @media (max-width: 749px) {
      margin-left: -70px;
    }
  }

  [data-tooltip]:after {
    position: absolute;
    bottom: 80%;
    left: 50%;
    margin-left: -5px;
    width: 0;
    border-top: 5px solid #000;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    content: " ";
    font-size: 0;
    line-height: 0;
  }

  [data-tooltip]:hover:before,
  [data-tooltip]:hover:after {
    visibility: visible;
    opacity: 1;
  }
}

.footer-bottom {
  background-color: #152332;
}

footer {
  margin-top: 20px;
  .footer_copyright {
    position: relative;
    text-align: center;
    padding: 20px 5px;
    .copyright {
      color: #fff;
      padding: 0;
      a {
        color: #ffffff;
        text-decoration: none;
        font-weight: 600;
      }
    }
    button {
      position: absolute;
      right: 30px;
      top: 7px;
      cursor: pointer;
      border-radius: 100%;
      min-width: 50px;
      height: 47px;
      border: 1px solid;
      svg {
        color: #fff;
      }
    }
  }
}
.inside_footer {
  width: 100%;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  z-index: 1;
  color: #fff;
  position: absolute;
  @media screen and (min-width: 991px) {
    // position: fixed;
  }
  a {
    color: #ffffff;
    text-decoration: none;
    font-weight: 600;
    img {
      width: 25px !important;
    }
  }
}
