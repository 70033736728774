.quizForm {
    .options {


        .fieldWrapper {
            width: 100%;
            margin-top: 25px;
            align-items: center;
            display: flex;

            .inputField {
                width: calc(100% - 40px);
            }
        }
    }
}

.quizMessage {
    text-align: center;
    font-weight: bold;

    &.success {
        color: #2c7e01;
    }

    &.error {
        color: #9c0303;
    }
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
    /* Make the calendar icon clickable */
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator:focus {
    outline: none;
}