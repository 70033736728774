.recentSec {
  width: 100%;
}
.course {
  padding: 20px;
  gap: 0px;
  margin: 0;
  .navLinks {
    max-width: calc(100% / 5 - 2%);
    width: 100%;
    @media screen and (max-width: 1280px) {
      max-width: calc(100% / 4 - 2%);
    }
    @media screen and (max-width: 1040px) {
      max-width: calc(100% / 3 - 2%);
    }
    @media screen and (max-width: 680px) {
      max-width: calc(100% / 2 - 2%);
    }
    @media screen and (max-width: 440px) and (min-width: 321px) {
      max-width: 70%;
      margin: 15px auto;
    }
    @media screen and (max-width: 320px) {
      max-width: 100%;
    }

    border-radius: 10px;
    text-align: left;
    margin: 10px 1% 0;
    text-decoration: none;
    min-height: 160px;
    min-width: 180px;
    overflow: hidden;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    position: relative;
    &:hover {
      .overlay {
        height: 100%;
        transform: translateY(-50%);
        .overlayTxt {
          opacity: 1;
        }
      }
    }

    .imgSec {
      overflow: hidden;
      height: 250px;
      border-bottom: 1px solid #dde3ff;
      text-align: center;
      position: relative;

      img {
        width: 100%;
        height: 100%;
      }
    }
    .textArea {
      padding: 15px;
      position: relative;
      .courseName,
      .secondText {
        font-weight: 600;
        font-size: 16px;
        // margin-left: 45px;
      }
      b {
        font-size: 14px;
        margin-right: 5px;
        color: #707070;
      }
      .classSec {
        border: 1px solid;
        border-radius: 100%;
        width: 45px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #cf4002;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 1px;
        color: #fff;
        position: absolute;
        left: 5px;
        top: 2px;
      }
    }

    &::after {
      content: "";
      background-color: #283849;
      width: 100%;
      height: 3px;
    }
  }
}

// flex accordion inner items
.innerAccordionHEad .MuiAccordionSummary-content {
  justify-content: space-between;
  align-items: center;
}

//theme base color changes
.light {
  .navLinks {
    border: 2px solid #dde3ff;
    // box-shadow: 0px 0px 14px 0px rgb(40, 108, 215, 0.2);
    background: #fff;
  }
}
.dark {
  .navLinks {
    border: 2px solid #283849;
    // box-shadow: 0px 0px 14px 0px rgb(177, 195, 222, 0.26);
    background: #334559;
  }
}
